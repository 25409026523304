// Generated by Framer (400c93f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, Link, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";
import * as sharedStyle from "../css/LC6TE31cM";

const enabledGestures = {sRAT6dzdP: {hover: true}};

const serializationHash = "framer-eEIsX"

const variantClassNames = {sRAT6dzdP: "framer-v-p5j5qq"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, link, tap, title, width, ...props}) => { return {...props, fF32h_OBQ: title ?? props.fF32h_OBQ ?? "Link", kwWR1ZLuI: tap ?? props.kwWR1ZLuI, Mm9yYAj7D: link ?? props.Mm9yYAj7D} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;title?: string;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, Mm9yYAj7D, fF32h_OBQ, kwWR1ZLuI, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "sRAT6dzdP", enabledGestures, ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTaplhzzwy = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (kwWR1ZLuI) {const res = await kwWR1ZLuI(...args);
if (res === false) return false;}
})

const sharedStyleClassNames = [sharedStyle.className]

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><Link href={Mm9yYAj7D} motionChild nodeId={"sRAT6dzdP"} scopeId={"wbHw6LNvF"} smoothScroll><motion.a {...restProps} {...gestureHandlers} className={`${cx(scopingClassNames, "framer-p5j5qq", className, classNames)} framer-om04cv`} data-framer-name={"navl ink"} data-highlight layoutDependency={layoutDependency} layoutId={"sRAT6dzdP"} onTap={onTaplhzzwy} ref={refBinding} style={{...style}} {...addPropertyOverrides({"sRAT6dzdP-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-dmuy5d"} data-styles-preset={"LC6TE31cM"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-a85af9cb-7834-4006-a277-2dd1295ae376, rgb(255, 255, 255)))"}}>Link</motion.p></React.Fragment>} className={"framer-1l9jozo"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"Ts60qAvtL"} style={{"--extracted-r6o4lv": "var(--token-a85af9cb-7834-4006-a277-2dd1295ae376, rgb(255, 255, 255))", opacity: 0.8}} text={fF32h_OBQ} variants={{"sRAT6dzdP-hover": {"--extracted-r6o4lv": "rgb(133, 164, 184)", opacity: 1}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({"sRAT6dzdP-hover": {children: <React.Fragment><motion.p className={"framer-styles-preset-dmuy5d"} data-styles-preset={"LC6TE31cM"} style={{"--framer-text-color": "var(--extracted-r6o4lv, rgb(133, 164, 184))"}}>Link</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.a></Link></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-eEIsX.framer-om04cv, .framer-eEIsX .framer-om04cv { display: block; }", ".framer-eEIsX.framer-p5j5qq { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 6px; height: min-content; justify-content: flex-start; overflow: hidden; padding: 6px 12px 6px 12px; position: relative; text-decoration: none; width: min-content; }", ".framer-eEIsX .framer-1l9jozo { -webkit-user-select: none; flex: none; height: auto; overflow: visible; position: relative; user-select: none; white-space: pre; width: auto; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-eEIsX.framer-p5j5qq { gap: 0px; } .framer-eEIsX.framer-p5j5qq > * { margin: 0px; margin-left: calc(6px / 2); margin-right: calc(6px / 2); } .framer-eEIsX.framer-p5j5qq > :first-child { margin-left: 0px; } .framer-eEIsX.framer-p5j5qq > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 34
 * @framerIntrinsicWidth 51
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"PBmYRoeNs":{"layout":["auto","auto"]}}}
 * @framerVariables {"Mm9yYAj7D":"link","fF32h_OBQ":"title","kwWR1ZLuI":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 * @framerColorSyntax true
 */
const FramerwbHw6LNvF: React.ComponentType<Props> = withCSS(Component, css, "framer-eEIsX") as typeof Component;
export default FramerwbHw6LNvF;

FramerwbHw6LNvF.displayName = "Nav / Nav Link";

FramerwbHw6LNvF.defaultProps = {height: 34, width: 51};

addPropertyControls(FramerwbHw6LNvF, {Mm9yYAj7D: {title: "Link", type: ControlType.Link}, fF32h_OBQ: {defaultValue: "Link", displayTextArea: false, title: "Title", type: ControlType.String}, kwWR1ZLuI: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerwbHw6LNvF, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})